import { dbg } from './utils/dbg';
import { loadDot, setDot } from './impress/dotLoader';
import { setPageViewId, getPageViewId } from './utils/pageView';
import { updateSession } from './session/sessionDetection';
import { executeQueues } from './utils/functionStack';
import { registerWritePawListenerForIframe, registerWritePawListenerForCode } from './rendering/renderRequestedPaw';
import { registerPawVisibilityListener } from './rendering/pawVisibilityListener';
import { enableConsentProvider } from './cmp/consentProvider';
import { setInitTimestamp } from './utils/timestamp';
import { sendSspLoaded } from './utils/events';
import initReporting from './utils/reporting';
import { isStandAlone, doNotLoadDOT } from './config/globalFlags';
import { setUpUtmMessageListener } from './session/leaderFollowerSync';
import { getConfig } from './config';
import { registerVastDataPayloadProvider } from '../modules/ads/getVastAds';
import { registerVastDataProvider } from '../modules/utils/vastData';

/* Has init() been run already? */
let wasInitialized = false;

/**
 * Spusti inicializaci ssp az po naceteni DOT
 */
const run = function () {
  dbg('info', '### initializating ssp after loading DOT ###');

  if (window.sznRecass && window.sznRecass.getPageViewId() !== null) {
    setPageViewId(window.sznRecass.getPageViewId());
  } else {
    setPageViewId();
    dbg('info', '#### pageViewId:', getPageViewId());
  }

  /* Init browser reporting (when supported) */
  initReporting(true);

  registerWritePawListenerForIframe();
  registerWritePawListenerForCode();
  registerPawVisibilityListener();
  registerVastDataProvider();
  registerVastDataPayloadProvider();
  enableConsentProvider();
  executeQueues();

  if (!getConfig().isFollowerSsp) {
    dbg('info', '### setting up utm message listener ###');
    setUpUtmMessageListener();
  }
};

/**
 * Main SSP init function.
 * Runs automatically by default. This can be overriden by setting
 * window._sspDoNotInit to true prior to ssp execution
 */
const init = () => {
  if (wasInitialized) {
    return;
  }

  wasInitialized = true;

  /* set init timestamp (start of lifetime) */
  setInitTimestamp();

  /* Create, update or destroy session cookie */
  updateSession();

  /* Generating a custom event 'ssploaded' */
  sendSspLoaded();

  if (isStandAlone() || doNotLoadDOT()) {
    setDot();
    run(); // no check for DOT, service guaranteed existence of DOT
    return;
  }

  loadDot().catch(dbg).finally(run);
};

export default init;
