import { dbg } from '#Modules/utils/dbg';
import { loadScript } from '#Modules/utils/loadScript';
import { sendHit } from '#Modules/utils/sendHit';
import { omitWww } from '#Modules/utils/urls';
import { getUrlParams } from '#Modules/utils/helpers/getUrlParams';
const adData = {};
export const getAdDataByZoneId = (zoneId) => adData[zoneId] || null;
export const addGamFlagsToPayload = (requestPayload) => {
    requestPayload.zones.forEach((zone) => {
        zone.options = { ...zone.options, gam: true };
    });
};
export const generateGamDimensionsFromMissAdData = (allowedDimensions, fallbackDimensions) => {
    if (Array.isArray(allowedDimensions)) {
        return allowedDimensions.map(({ width, height }) => [width, height]);
    }
    else {
        return fallbackDimensions;
    }
};
export const isBlacklisted = () => {
    const blacklist = ['iprima.cz', 'kurzy.cz', 'nakluky.cz', 'autosalon.tv', 'playzone.cz', 'aimatch.com'];
    const hostname = window.location.hostname.toLowerCase();
    return blacklist.includes(omitWww(hostname));
};
const initializeGAMObject = () => {
    if (!window.googletag) {
        window.googletag = {
            cmd: [],
        };
    }
};
/**
 * For "empty" ads we can request GAM ad (rendered to specified new HTML element)
 */
export const gamFallback = (ad, data) => {
    if (!ad?.zoneId || !data?.options?.sspRendering || !data?.width || !data?.height || !data?.id) {
        dbg('info', 'GAM fallback - missing data');
        return;
    }
    if (isBlacklisted()) {
        dbg('info', 'GAM fallback - service blacklisted');
        return;
    }
    dbg('info', 'GAM fallback - starting');
    const elementId = 'div-gpt-ad-1677658328819-' + ad.zoneId;
    const dimensions = generateGamDimensionsFromMissAdData(ad.formats?.sizes, [[data.width, data.height]]);
    // load google script & config
    loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js')
        .then(() => {
        try {
            // prepare div for GAM rendering
            const gElement = document.createElement('div');
            gElement.setAttribute('id', elementId);
            // get "empty" ad element
            const emptyAdElement = document.getElementById(data.id.toString());
            // append to dom (after "empty" element or at the end of parent)
            if (emptyAdElement?.parentElement && emptyAdElement?.nextSibling) {
                emptyAdElement.parentElement.insertBefore(gElement, emptyAdElement.nextSibling);
            }
            else if (emptyAdElement.parentElement) {
                emptyAdElement.parentElement.appendChild(gElement);
            }
            else {
                dbg('error', 'GAM fallback - no element to render');
                return;
            }
            // GAM config
            initializeGAMObject();
            window.googletag.cmd.push(function () {
                window.googletag
                    .defineSlot('/22819958440/ca-pub-2127466377868208-tag', dimensions, elementId)
                    .addService(window.googletag.pubads());
                window.googletag.pubads().enableSingleRequest();
                window.googletag.enableServices();
            });
            dbg('info', 'GAM fallback - configured', dimensions, elementId);
            // render
            window.googletag.cmd.push(function () {
                dbg('info', 'GAM fallback - require display', dimensions, elementId);
                window.googletag.display(elementId);
            });
            // TEMPORARY log GAM calls
            const { requestId, zoneId, impressId } = ad;
            sendHit({ r: requestId, z: zoneId.toString(), i: (impressId || 0).toString(), t: 'gam', v: '1' });
        }
        catch (e) {
            dbg('error', 'GAM fallback - ad rendering failed', e);
        }
    })
        .catch((e) => {
        dbg('error', 'GAM fallback - gpt.js loading failed', e);
    });
};
export const requestGamAd = async (ad, data) => {
    if (!ad.zoneId || !data?.options?.sspRendering || !data?.width || !data?.height || !data?.id) {
        dbg('info', 'GAM TEST - missing data');
        return false;
    }
    // RTB Testing web provide webId as null - we need to check web through hostname
    const isRtbTestingWeb = location.host === 'rtb.seznam.cz';
    if (ad.zoneId !== 292746 || !isRtbTestingWeb) {
        dbg('info', 'GAM TEST - Test is not supported on this website and zone id');
        return false;
    }
    dbg('info', 'GAM TEST - starting');
    const slotUnitPath = '/22819958440/SZN_GAM_PROMISE_ALL_SIZES_TEST';
    /*
     * We store AD data for later use in GAM custom creative
     * if GAM does not provide relevant ad for this zone.
     */
    adData[ad.zoneId] = { ad, data };
    const elementId = 'div-gpt-ad-1740141973465-' + ad.zoneId;
    const dimensions = generateGamDimensionsFromMissAdData(ad.formats?.sizes, [[data.width, data.height]]);
    try {
        await loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
        const gamTestElement = document.createElement('div');
        gamTestElement.setAttribute('id', elementId);
        const emptyAdElement = document.getElementById(data.id.toString());
        if (emptyAdElement?.parentElement && emptyAdElement?.nextSibling) {
            emptyAdElement.parentElement.insertBefore(gamTestElement, emptyAdElement.nextSibling);
        }
        else if (emptyAdElement?.parentElement) {
            emptyAdElement.parentElement.appendChild(gamTestElement);
        }
        else {
            dbg('error', 'GAM TEST - no element to render');
            return false;
        }
        // GAM config
        initializeGAMObject();
        // Create a Promise with a timeout for TEST
        return new Promise((resolve) => {
            let currentSlot = null;
            const timeoutId = setTimeout(() => {
                dbg('info', 'GAM TEST - Timeout reached, Request SSP Ad');
                if (currentSlot) {
                    currentSlot = null;
                    window.googletag.destroySlots([currentSlot]);
                }
                resolve(false);
            }, 10000);
            // TODO: URL parameter only for testing
            const seznamPrice = getUrlParams().seznamPrice?.toString() || '11';
            window.googletag.cmd.push(function () {
                currentSlot = window.googletag
                    .defineSlot(slotUnitPath, dimensions, elementId)
                    .addService(window.googletag.pubads())
                    .setTargeting('seznamPrice', seznamPrice);
                window.googletag.pubads().enableSingleRequest();
                window.googletag.enableServices();
                window.googletag.pubads().addEventListener('slotRenderEnded', function ({ isEmpty, slot }) {
                    if (slot.getSlotElementId() === elementId) {
                        clearTimeout(timeoutId);
                        if (!currentSlot) {
                            dbg('error', 'GAM TEST - Do not render GAM due to timeout');
                            return resolve(false);
                        }
                        if (isEmpty) {
                            dbg('info', 'Ad rendered but is empty');
                            return resolve(false);
                        }
                        dbg('info', `Ad successfully rendered on slot ${slot.getSlotElementId()}`);
                        return resolve(true);
                    }
                });
            });
            // Render AD
            window.googletag.cmd.push(function () {
                dbg('info', 'GAM TEST - require display', dimensions, elementId);
                window.googletag.display(elementId);
            });
            const { requestId, zoneId, impressId } = ad;
            sendHit({ r: requestId, z: zoneId.toString(), i: (impressId || 0).toString(), t: 'gam', v: '1' });
        });
    }
    catch (error) {
        dbg('error', 'GAM TEST - Failed to load ad or render ad', error);
        return false; // In case of any error, return false
    }
};
