import { COLLOCATION_FLAG_SAFE, COLLOCATION_UNSAFE_KEYWORDS, COLLOCATION_WHITELIST } from '#Constants';
import { dbg } from '#Modules/utils/dbg';
import { omitWww } from '#Modules/utils/urls';
let collocationFlags = [];
/**
 * Collocations (string arrays) helpers
 */
const _hasIntersection = (a, b) => a.some((element) => b.includes(element));
const _sanitizeString = (str) => str.trim().toLowerCase();
const _hasSameMember = (a, b) => _hasIntersection(sanitizeStrings(a), sanitizeStrings(b));
const _resetCollocation = () => (collocationFlags = []);
export const sanitizeStrings = (arr) => arr.map(_sanitizeString);
/**
 * Detect collocations against UN/SAFE keywords
 */
export const evaluateCollocation = (keywords) => {
    dbg('info', `[collocation.js] Keywords provided: ${keywords.join(', ')}`);
    _resetCollocation();
    // Unsafe keyword[s] is not present
    if (!_hasSameMember(COLLOCATION_UNSAFE_KEYWORDS, keywords)) {
        collocationFlags.push(COLLOCATION_FLAG_SAFE);
    }
    dbg('info', `[collocation.js] Collocation detected: "${collocationFlags.join(', ')}"`);
};
/**
 * Add collocation flags to each zone (inside options)
 */
export const addCollocationFlagsToPayload = (requestPayload) => {
    if (collocationFlags.length) {
        requestPayload.zones.forEach((_, index, zones) => {
            zones[index].options = zones[index].options || {};
            const prevCollocation = zones[index].options.collocation;
            const addCollocation = collocationFlags.join(' ');
            zones[index].options.collocation = prevCollocation ? `${prevCollocation} ${addCollocation}` : addCollocation;
        });
    }
};
/**
 * We allow collocation only for whitelisted domains
 */
export const allowCollocation = () => COLLOCATION_WHITELIST.includes(omitWww(document.location.hostname));
