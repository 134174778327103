import { dbg } from '../utils/dbg';
import urlDataToCallback from './urlDataToCallback';
import writeAd from '../rendering/writeAd';
import { setupDocumentWrite } from '../utils/documentwrite';
import { getCompleteData, addPositions } from './adRepository';
import { emitDebugEvent } from '../utils/events';
import { hitAction } from '../impress/testMetric';
import { logBackgroundAdReload } from '../impress/backgroundAdsReloads';

/**
 * Vykreslí reklamu nebo zavolá callback.
 * @param adInput {Object} - jedna reklama z ssp
 * @param dataInput {Object} - definice pozice
 */
const processAdData = (adInput, dataInput) => {
  emitDebugEvent('processAdDataCallStart', { adInput, dataInput });
  dbg('info', '### starting _processAdData ###');

  /* Attempt to replace document.write if enabled */
  setupDocumentWrite();

  const adObjects = getCompleteData([dataInput], [adInput]);
  addPositions(adObjects);

  const { ad, data } = adObjects[0];

  if (!ad?.type || !data) {
    dbg('error', 'invalid ad data', { ad, data });
    return;
  }

  if (data.callback && typeof data.callback === 'function') {
    dbg('info', '## call external callback for position', data.zoneId);
    switch (ad.type) {
      case 'vast':
        dbg('call VAST custom callback with:', data.callback, data);
        hitAction('processAdDataVAST', ad.zoneId);
        logBackgroundAdReload(ad);
        data.callback(data.options?.doNotParseVastData ? ad : ad.data, data);
        break;
      case 'code_url':
        dbg('call CODE_URL custom callback with:', data.callback, data);
        hitAction('processAdDataCodeURL', ad.zoneId);
        urlDataToCallback(ad, data);
        break;
      case 'json_url':
        if (data.options && data.options.jsonPreloadDisabled) {
          dbg('call JSON_URL custom callback with:', data.callback, data);
          hitAction('processAdDataJSONURLCallbackPreloadDisabled', ad.zoneId);
          logBackgroundAdReload(ad);
          data.callback(ad, data);
        } else {
          dbg('call JSON_URL custom callback with (urlDataToCallback):', data.callback, data);
          hitAction('processAdDataJSONURLCallback', ad.zoneId);
          urlDataToCallback(ad, data);
        }
        break;
      default: // vast_url, json
        hitAction('processAdDataDefault', ad.zoneId);
        logBackgroundAdReload(ad);
        data.callback(ad, data);
    }
  } else {
    if (ad.type === 'code_url') {
      // url_code je specificky, muze a nemusi mit vlastni callback
      urlDataToCallback(ad, data);
    } else {
      dbg('info', '## call writeAd for position', data.zoneId);
      hitAction('processAdDataWriteAd', ad.zoneId);
      writeAd(ad, data);
    }
  }
};

export default processAdData;
