import { getParamFromQuerystringOrHash } from '../utils/helpers/getUrlParams';
import { omitWww } from '../utils/urls';

/** Internal services blacklisted from using CMP */
const blacklist = [];

export const inBlacklist = (hostname) => {
  return (
    blacklist.includes(omitWww(hostname)) ||
    // for testing pourposes
    getParamFromQuerystringOrHash('ignore_cmp') === '1'
  );
};

const chunksWhitelist = ['kuchticka.cz'];

export const inChunksWhitelist = (hostname) => {
  return (
    chunksWhitelist.includes(omitWww(hostname)) ||
    // for testing pourposes
    getParamFromQuerystringOrHash('cmp_chunks') === '1'
  );
};
