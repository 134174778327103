import { dopUrlParameters, optionsParams, params } from '#Modules/config/configValues';
import getPackageVersion from '#Modules/config/getPackageVersion';
import { prepareConfig } from '#Modules/config/validation';
import { getCustoms } from '#Modules/customs';
import sync3rdparty from '#Modules/impress/sync3rdParty';
import { getOverrides } from '#Modules/utils/getOverrides';
import getLocation from '#Modules/utils/helpers/getLocation';
import { CMP_URL, SSP_URL } from '#Constants';
import { SspAllowedSourcesObject } from '@iva/types/dot';
/**
 * @module config
 * @desc Module containing functions for reading and modifying the sssp configuration object.
 */
/**
 * SSSP configuration object.
 * @link https://ssp.seznam.cz/static/doc/nastaveni for public configuration options with descriptions.
 */
const config = {
    ab: null,
    activeUser: null,
    adblock: false,
    /* The allowed source of the visit to the site where the ad is published. */
    allowedSources: SspAllowedSourcesObject,
    badge: null,
    callCallbackOnError: false,
    cidsAutoSend: false,
    /* The value can be taken from window.localStorage.ssspCustomPaths: "{\"cmpServer\":\"<value>\"}". */
    cmpData: null,
    cmpServer: getCustoms('cmpServer') || `${CMP_URL.PROTOCOL}${CMP_URL.HOSTNAME}${CMP_URL.PATH_TO_CMP}`,
    cookieDomain: '',
    cookieExpiration: 30,
    /* HTML element of currently executing script. */
    currentScript: document.currentScript,
    debugExtensionId: 'ljpnolgcojkhoemjaccecimbmiejkpmm',
    /* Additional url parameters (transition from news feed). */
    dopUrlParameters,
    /* Array of ad zones for DOT. Values are stored in the format: ['zoneId:zoneId1', 'zoneId:zoneId2',...]. */
    dotMeasureZones: [],
    /* Array of ids of ad zones for DOT. */
    dotMeasureZonesIds: [],
    fallbackToGAM: false,
    iframeStaticServer: getCustoms('iframeStaticServer') || SSP_URL.IFRAME_HOSTNAME,
    /* Attribute 'src' of iframe tag with ad. */
    iframeUrl: `/static/html/${window.location.host}/reklama.html`,
    isFollowerSsp: window !== window.top && window.Cypress === undefined,
    /* The method of the xhr request. */
    method: 'POST',
    optionsParams,
    packageVersion: getPackageVersion(),
    params,
    /* Path for xhr requests. */
    path: '/xhr',
    premium: null,
    /* Callback function is called if the session started by the user's arrival by clicking from news feed to homepage is valid Seznam.cz. */
    preparePositionsCallback: null,
    /* Protocol for communicate with ssp. */
    protocol: SSP_URL.PROTOCOL,
    /* Page View Id for Single-Page Application (SPA). During page initialization the value is set with window.sznRecass.getPageViewId() or is randomly generated. */
    pvId: '',
    /* Callback function that SSP calls when an xhr request fails. */
    requestErrorCallback: null,
    rusId: null,
    said: null,
    /* SSP server (From here, advertising will be issued). The value can be taken from window.localStorage.ssspCustomPaths: "{\"server\":\"<value>\"} */
    server: getCustoms('server') || SSP_URL.HOSTNAME,
    serviceIdForAllZones: null,
    /* Current site. The value is sent in payload of xhr requests */
    site: getLocation(),
    source: '',
    /* SSP static server. The value can be taken from window.localStorage.ssspCustomPaths: "{\"staticServer\":\"<value>\"} */
    staticServer: getCustoms('staticServer') || getOverrides().static || SSP_URL.HOSTNAME,
    /* Testing mode for partners. If true, then url of xhr request has parameter `test=1`. On the server, such xhr requests aren't taken into account in statistics. */
    test: false,
    /* SSP API version. This is used in xhr requests. */
    version: 'v1',
    webId: null,
};
config.dotMeasureZones = config.dotMeasureZonesIds.map((zoneId) => `"zoneId":${zoneId}`);
/**
 * Function for getting the configuration
 */
export const getConfig = () => config;
/**
 * Updates the configuration object with the new valueswrite
 */
export const _writeConfig = (newConfig) => {
    Object.assign(config, newConfig);
    if (window.sssp) {
        Object.assign(window.sssp.conf, config);
    }
};
/**
 * Function that updates the configuration object with the new allowed values
 */
export const setConfig = (newConfig) => {
    const preparedConfig = prepareConfig(newConfig);
    if ('activeUser' in preparedConfig) {
        window.sznIVA?.IS?.updateIdentities({ login: preparedConfig.activeUser });
    }
    if (!preparedConfig) {
        return;
    }
    _writeConfig(preparedConfig);
    // synchronization of 3rd party cookies in iframes (e.g. apnexus, pubmatic, etc.)
    sync3rdparty();
};
