import { dbg } from '../utils/dbg';
import { getConfig } from '../config';
import sync3rdparty from '../impress/sync3rdParty';
import logAds from '../impress/logAds';
import createRequestPayload from './createRequestPayload';
import { validatePosition } from '../utils/validatePosition';
import { sessionExists } from '../session/sessionDetection';
import { requestAds } from '../utils/request';
import { emitDebugEvent } from '../utils/events';
import { cmpInit } from '../cmp/cmpLoader';
import done from './done';
import { getJson } from '../utils/helpers/getJson';
import { setCids, getCids, addCidsFromResponse } from './cids';
import { addCollocationFlagsToPayload, allowCollocation, evaluateCollocation } from '../utils/collocation';
import { getKeywords } from '../utils/keywords';
import { getPageViewId, pageViewIdChanged } from '../utils/pageView';
import { safelyExecuteCallback } from '../utils/safeCallback';
import { isStandAlone } from '../config/globalFlags';
import { addGamFlagsToPayload } from './gam';
import { syncUtmSourceWithLeader } from '../session/leaderFollowerSync';

/**
 * @module sssp/getAds
 * @desc Module describes method <code>getAds</code> for requesting ads from the server by zones.</br>
 * <a href="https://ssp.seznam.cz/static/doc/#pouziti-pro-zacatecniky-singlerequest">Public description of how to use the method getAds (singlerequest use)</a></br>
 * <a href="https://ssp.seznam.cz/static/doc/#multirequest-pouziti">Public description of how to use the method getAds (multirequest use)</a></br>
 */

let cmpRequested = false;

/**
 * Main method for calling and displaying ads. Allows requesting and displaying
 * a single zone (single object as the first parameter) or multiple zones by
 * passing an array of zone objects.<br/>
 * Public function <code>sssp.getAds</code></br></br>
 * <a href="https://ssp.seznam.cz/static/doc/#prehled-nastaveni-ssp">Public description of the incoming parameters of the method</a>
 * (the public documentation describes some of the fields, not all)
 *
 * @param {Object|Object[]} positions Configuring zones on the page <br/>
 * <b>Object</b> - singlerequest use <br/>
 * <b>Object[]</b> (Array of Object) - multirequest use (the object format is used the same) <br/>
 * <a href="https://ssp.seznam.cz/static/doc/#prehled-nastaveni-zony">Public description of configuring zones</a>
 * @param {string} positions.id - The identifier of the HTML element to add the ad to.<br/>
 * Not required if the <code>positions.callback</code> is specified
 * Element with ad can be found by <code>document.getElementById(positions.id);</code>
 * @param {number} positions.zoneId - Unique ad zone identifier. <br/>
 * This number is assigned to the zone automatically when an ad code is generated in the <a href="https://partner.seznam.cz/seznam-ssp/">partner interface</a>
 * @param {number} positions.width - Maximal ad width in pixels. <br/>
 * The server searches for an advertising banner suitable in size. One dimension (width or height) must be specified.
 * @param {number} [positions.height] - Maximal ad height in pixels. <br/>
 * The server searches for an advertising banner suitable in size. One dimension (width or height) must be specified.
 * @param {number} [positions.minWidth] - Minimal ad width in pixels <br/>
 * Specifies the size for different types of devices
 * @param {number} [positions.minHeight] - Minimal ad height in pixels <br/>
 * Specifies the size for different types of devices
 * @param {object[]} [positions.elements] - The field of elements where you can list the ad, and their dimensions
 * @param {string} [positions.serviceId] - Service ID of zone.
 * @param {string} [positions.contentId] -  Content ID of zone.
 * @param {boolean} [positions.cidsAutoSend=false] - If true, then collect CIDs from response
 * @param {function} [positions.callback] - Function that browser calls when ad arrives from the SSP server
 *
 * @param {Object} [positions.options]
 * @param {boolean} [positions.options.branding] - Whether wider variant of leaderboard can be served.
 * @param {string} [positions.options.section] Section for hierarchical ad targeting. <br/>
 * Value is passed only to Advert DSP, for backward compatibility.
 * @param {string} [positions.options.collocation] Ad targeting keywords. <br/>
 * Value is passed only to Advert DSP, for backward compatibility.
 * @param {object} [positions.options.passons] Spot variant flags. Ignored for video zones. <br/>
 * Value is passed only to Advert DSP, for backward compatibility.
 * @param {string} [positions.options.flags] Template arguments. <br/>
 * Value is passed only to Advert DSP, for backward compatibility.
 * @param {number} [positions.options.spotId] ID of a spot to be served. <br/>
 * Value is passed only to Advert DSP and only when *test=1*. Advert DSP sends bid for this spotId and then this bid has to win SSP auction to be served.
 * @param {number} [positions.options.minDuration] Minimal video duration in seconds. <br/>
 * Attribute is ignored for zones that do not serve video ads.
 * @param {number} [positions.options.maxDuration] Maximal video duration in seconds. <br/>
 * Attribute is ignored for zones that do not serve video ads.
 * @param {string[]} [positions.options.videoFormats] Supported MIME types list.<br/>
 * Empty list means it is possible to server any format.<br/>
 * Attribute is ignored for zones that do not serve video ads.
 * @param {string[]} [positions.options.protocols] Supported protocols (VAST, VPAID etc.).<br/>
 * Attribute is ignored for zones that do not serve video ads.
 * @param {boolean} [positions.options.skippable] If player allows ad skipping.
 * @param {function} [positions.options.jsonPreloadDisabled] For ad type json_url disables automatic JSON download
 * @param {function} [positions.options.infoCallback] Function is called for each ad position after the data from the SSP is retrieved
 * @param {function} [positions.options.infoErrorCallback]  Function is called for each ad position that is incorrectly configured
 * @param {boolean} [positions.options.noSize] If set to true, SSP will not require a position dimension
 * @param {number[]} [positions.options.nativeTemplate] Which template to use when asking DSPs for native ads.
 * @param {number[]} [positions.options.nativeVersion] Which version to use when asking DSPs for native ads.
 * @param {boolean} [positions.options.sspRendering] Whether the ad is rendered by ssp script (true) or externally (false, null).
 * @param {number} [positions.options.sfExpandZIndex=1] Z-index value for an expanding overlay banner
 * @param {number} [positions.options.pawTimeout=1000] Sets paw rendering delay
 * @param {boolean} [positions.options.pawDisable=false] Determines whether a paw is hidden or not
 * @param {boolean} [positions.options.disableScaling] If disableScaling = true, SSP does not change size for nonresponsive ads
 * @param {number} [positions.options.reloadCount] Number of ad reloads performed on the zone, default 0
 * DSP test params
 * @param {string} [positions.preview.adType] optional addType
 * @param {string} [positions.preview.bannerid] optional bannerid
 * @param {string} [positions.preview.crid] optional crid
 * @param {string} [positions.preview.deliveryType] optional deliveryType
 * @param {string} [positions.preview.dsp] optional dsp setting ("Sklik", "Advert", "Ogar", "Onegar"). If not specified the SSP server sets the value to "Sklik".
 * @param {string} [positions.preview.mediaType] optional mediaType
 *
 * Opt params
 * @param {Object} [opt]  Additional request configuration
 * @param {function} [opt.AMPcallback] Function calls from AMPHTML
 * @param {string[]} [opt.cids] Campaigns identifiers
 * @param {boolean} [opt.grantConsent] turn on grantConsent for DSP testing page
 * @param {boolean} [opt.pvIdChangeIgnore] turn off pvId change handlind (xhr aborting)
 * @param {Object} [opt.config.ab] A/B testing variant e.g. { recass_ab_variant_test: "ab_variant" }
 * @param {string} [opt.config.source] UTM Source parameter, indicating whether user has arrived from HP. See session/ for more information.
 */

const getAds = async function (positions, opt) {
  emitDebugEvent('getAdsCallStart', { positions });
  const {
    isFollowerSsp,
    preparePositionsCallback,
    requestErrorCallback,
    cidsAutoSend,
    serviceIdForAllZones,
    pvId,
    fallbackToGAM,
  } = getConfig();

  if (opt && opt.cids) {
    setCids(opt.cids);
  }

  /* Execute user-defined callback if we have an active session cookie */
  if (typeof preparePositionsCallback === 'function' && sessionExists()) {
    safelyExecuteCallback(preparePositionsCallback);
    emitDebugEvent('preparePositionsCallbackCalled', { preparePositionsCallback });
  }

  sync3rdparty(); // volani sync kodu apnexus, pubmatic, ...

  dbg('info', '### starting get ads ###');
  dbg('with parameters', positions, opt);
  if (typeof positions !== 'object') {
    dbg('error', 'no valid parameters: types', positions);
    emitDebugEvent('errorNoValidParametersType', { positions });
    return;
  } else if (Array.isArray(positions) && !positions.length) {
    dbg('error', 'no valid parameters: array length 0', positions);
    emitDebugEvent('errorNoValidParametersLenght', { positions });
    return;
  }

  /* If `positions` is an Object, convert it to Array of length = 1 */
  if (!Array.isArray(positions)) {
    positions = [positions];
    dbg('data object transformed to array', positions);
    emitDebugEvent('positionTransformed', { positions });
  }

  /* If a "serviceIdForAllZones" is set in config, this "serviceIdForAllZones" will be used as "serviceId" for all positions. */
  if (serviceIdForAllZones) {
    positions.forEach((position) => {
      position.serviceId = serviceIdForAllZones;
    });
  }

  /* Request site's CMP config, sync consent cookies, maybe even display a CMP dialog */
  if (!isStandAlone() && !cmpRequested) {
    cmpInit(positions[0].zoneId);
    cmpRequested = true;
  }

  /* Log to Reporter */
  logAds(positions);

  /* Filter out invalid positions */
  const validPositions = positions.map((position) => validatePosition(position, opt)).filter(Boolean);
  emitDebugEvent('validatingPositionsEnd', { validPositions });

  /* Get current array of cids */
  const cids = getCids();

  /* If we're in an iframe, get the utm source from the master SSP */
  if (isFollowerSsp) {
    dbg('info', 'Getting utm source from leader SSP');
    const utmSource = await syncUtmSourceWithLeader();
    dbg('info', 'Utm source from leader SSP', utmSource);
    opt = opt ?? {};
    opt.config = opt.config ?? {};
    opt.config.source = utmSource;
  }

  /* Get full request data */
  const requestPayload = await createRequestPayload({ zones: validPositions, opt, cids });

  if (allowCollocation()) {
    if (pageViewIdChanged(getPageViewId())) {
      evaluateCollocation(getKeywords());
    }

    addCollocationFlagsToPayload(requestPayload);
  }

  if (fallbackToGAM) {
    addGamFlagsToPayload(requestPayload);
  }

  emitDebugEvent('requestPayloadEnd', { requestPayload });

  dbg('merged prefix and data object', requestPayload);

  /* Finally, request the ads */
  const handlePageViewIdChange = typeof opt?.pvIdChangeIgnore === 'boolean' ? !opt.pvIdChangeIgnore : false;
  requestAds(requestPayload, pvId, handlePageViewIdChange)
    /* Success, continue in `done` */
    .then(async (response) => {
      if (!response) {
        return;
      }
      const responseParsed = getJson(response);

      if (cidsAutoSend) {
        addCidsFromResponse(responseParsed);
      }

      await done(response, validPositions, opt, requestPayload);
    })

    /* Error, invoke website-defined callback */
    .catch((error) => {
      const { errorName, errorCode } = error;
      dbg('error', errorName);
      emitDebugEvent('xhrError', { error });
      if (typeof requestErrorCallback === 'function') {
        requestErrorCallback(errorName, errorCode);
      }
    });

  emitDebugEvent('getAdsEnded', {});
};

export default getAds;
