import { dbg } from '../utils/dbg';
import { getUrlParams } from '../utils/helpers/getUrlParams';
import { getImageBaseUrl } from '../utils/urls';

export const getCurrentDateFormatted = () => {
  return new Date().toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const isItDspTestPage = () => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const keys = Array.from(urlObj.searchParams.keys());
  return keys.some((key) => key.includes('ssp_zoneId') || key.includes('ssp_crid') || key.includes('ssp_bannerid'));
};

export const parsedDspUrlParams = () => {
  const urlObj = new URL(window.location.href);
  const keys = Array.from(urlObj.searchParams.keys());
  const trimKeyRegex = /^ssp_(.*?)(-\d+)?$/;
  const valuesToBeParsedToNumber = new Set(['zoneId', 'width', 'height', 'minWidth', 'minHeight']);

  const dspParams = keys.reduce((acc, key) => {
    const identificator = key.match(/\d+/g);
    if (!acc[identificator]) {
      acc[identificator] = {};
    }
    const trimmedKey = key.replace(trimKeyRegex, '$1');
    acc[identificator][trimmedKey] = valuesToBeParsedToNumber.has(trimmedKey)
      ? Number(urlObj.searchParams.get(key))
      : urlObj.searchParams.get(key);
    return acc;
  }, {});

  return Object.values(dspParams);
};

export const getDspParamsForZone = ({ zone, isVastRequest }) => {
  const dspParams = parsedDspUrlParams();
  const positionKeys = ['width', 'height', 'minHeight', 'minWidth'];
  const previewKeys = ['adType', 'bannerid', 'crid', 'deliveryType', 'dsp', 'mediaType'];

  const dspParamsForZone = dspParams.find((param) => {
    // for VAST requests we want to show the DSP params only if the target is '1'
    const isTargetMatch = isVastRequest ? param.target === '1' : param.target !== '1';

    // Check if the zoneId matches or if crid or bannerid are present with dsp 'advert'
    const isZoneMatch =
      param.zoneId === zone.zoneId ||
      (param.crid && !param.zoneId) ||
      (param.bannerid && param.dsp?.toLowerCase() === 'advert' && !param.zoneId);

    return isTargetMatch && isZoneMatch;
  });

  if (!dspParamsForZone) {
    dbg('DSP: no valid params or combination of params for position:', zone);
    return null;
  }

  const zoneParams = Object.entries(dspParamsForZone).reduce((acc, [key, value]) => {
    // VAST requests should contain only the preview keys
    if (!isVastRequest && positionKeys.includes(key)) {
      acc[key] = value;
    }

    if (previewKeys.includes(key)) {
      if (!acc.preview) {
        acc.preview = {};
      }

      acc.preview[key] = value;
    }

    return acc;
  }, {});

  dbg('DSP: added DSP params into position:', zone);
  return zoneParams;
};

export const createDspStripeEl = ({ baseUrl, message, zoneId, isErrorStripe }) => {
  const stripeEl = document.createElement('div');
  stripeEl.id = isErrorStripe ? `dspTestError${zoneId ? '-' + zoneId : ''}` : 'dspTestInfo';
  stripeEl.style.cssText = `
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: ${isErrorStripe ? '#F08B34' : '#0C99CA'};
  color: #FFFFFF;
  font-family:  Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  `;

  stripeEl.innerHTML = `
  <img src="${baseUrl}${isErrorStripe ? '/dspTest-error.svg' : '/dspTest-info.svg'}"  alt="Adpreview" height="16" width="16" >
  <span style="font-weight: 700;">Živý&nbsp;náhled.</span>
  <span>${message}</span>
  `;

  return stripeEl;
};

export const shouldShowDspStripe = (urlParams) => {
  // should show stripe for dsp test page
  const isDspTestPage = isItDspTestPage();
  // should NOT show stripe if params below are set
  const shouldHideInfoStripe = urlParams?.ssp_infoStripe === 'false';
  // TEMPORARY disable on screenshotator page for now, screenshotator logic still keeped in place
  const isScreenshotatorPage = urlParams?.screenshot === 'true';
  const isVastDspTestPage = Object.entries(urlParams).some(
    ([key, value]) => key.includes('ssp_target') && value === '1'
  );

  return ![shouldHideInfoStripe, isVastDspTestPage, isScreenshotatorPage, !isDspTestPage].some(Boolean);
};

export const createDspInfoStripe = () => {
  const urlParams = getUrlParams();

  // info stripe should be not shown on screenshotator page, vast dsp test page or if ssp_infoStripe is set to false
  // if we don't want to show into stripe just return no-operation function
  if (!shouldShowDspStripe(urlParams)) {
    // no-op
    return () => {};
  }

  dbg('info', 'DSP test page detected. Appending DSP info stripe.');

  const dspBarEl = document.createElement('div');
  dspBarEl.style.cssText = `
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  `;

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => document.body.appendChild(dspBarEl));
  } else {
    document.body.appendChild(dspBarEl);
  }

  const shadow = dspBarEl.attachShadow({ mode: 'open' });

  const baseUrl = getImageBaseUrl();
  const message =
    urlParams?.screenshot === 'true'
      ? `Screenshot pořízen ${getCurrentDateFormatted()}`
      : 'Tento náhled je vizualizace jak může vaše reklama vypadat na tomto webu.';

  const infoBarEl = createDspStripeEl({ baseUrl, message, isErrorStripe: false });
  shadow.append(infoBarEl);

  const dspParams = parsedDspUrlParams();
  let showErrorBar = true;

  return (serverResponse) => {
    const hasEveryZoneError = serverResponse.ads.every((ad) => ad?.dbginfo?.error);

    // There may be multiple XHR requests sent in one page visit. They may also be delayed until user scrolls down far enough. In a case where the first request is successful, we do not want to show the error stripe ever again, even if the following requests fail.
    if (showErrorBar && !hasEveryZoneError) {
      showErrorBar = false;
    }

    dspParams.forEach(({ crid, zoneId }) => {
      const responseZoneForDSP = serverResponse.ads.find((ad) => ad.zoneId === zoneId);
      if (zoneId && crid && responseZoneForDSP?.dbginfo?.error) {
        const stripeEl = createDspStripeEl({
          baseUrl,
          message: `Neplatná konfigurace AdPreview pro zoneId: ${zoneId}.`,
          isErrorStripe: true,
          zoneId,
        });
        shadow.prepend(stripeEl);
      }

      if (!zoneId && crid && hasEveryZoneError && showErrorBar) {
        const stripeEl = createDspStripeEl({
          baseUrl,
          message: 'Vaší reklamě aktuálně neodpovídá žádná zóna pro vykreslení (zkuste scrollovat na konec stránky).',
          isErrorStripe: true,
        });
        shadow.prepend(stripeEl);
        showErrorBar = false;
      }

      if (!zoneId && crid && !hasEveryZoneError) {
        const errorStripeEl = shadow.querySelector('#dspTestError');
        errorStripeEl?.remove();
      }
    });
  };
};

export const appendDspInfoEl = createDspInfoStripe();
