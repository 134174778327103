import { dbg } from '#Modules/utils/dbg';
import { processSspAd } from '#Modules/ads/done';
import { getAdDataByZoneId } from '#Modules/ads/gam';
/*
 * If GAM does not provide relevant ad for this zone,
 * the custom creative script will be delivered.
 * This script will remove the GAM ad and continue with Seznam AD.
 * https://support.google.com/admanager/answer/3185155?hl=en&ref_topic=10083476&sjid=12037626429500086839-EU
 */
const handleGamNoAdResponse = (slotUnitPath) => {
    if (typeof slotUnitPath !== 'string') {
        dbg('error', '### GAM TEST - slot unit path is not defined properly');
        return;
    }
    dbg('info', '### GAM does not provide relevant ad for this zone - use Seznam ad');
    // Get first ad slot
    const slotElementId = window.googletag?.pubads?.().getSlotIdMap?.()[`${slotUnitPath}_0`]?.getSlotElementId() ?? '';
    if (!slotElementId || typeof slotElementId !== 'string') {
        dbg('error', '### GAM TEST - slot element id is not defined properly');
        return;
    }
    // Remove GAM ad
    document.getElementById(slotElementId)?.remove();
    const elementIdParts = slotElementId.split('-');
    const zoneId = Number(elementIdParts[elementIdParts.length - 1]);
    const { ad, data } = getAdDataByZoneId(zoneId);
    if (!ad || !data) {
        dbg('error', 'Ad info not found');
        return;
    }
    // Create Seznam ad
    processSspAd(ad, data);
};
export default handleGamNoAdResponse;
