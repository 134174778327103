import { dbg } from '../utils/dbg';
import writeAd from '../rendering/writeAd';
import { safelyExecuteCallback } from '../utils/safeCallback';
import { logBackgroundAdReload } from '../impress/backgroundAdsReloads';

/**
 * Callback vypisu url kde se nachazi JSON nebo HTML data banneru
 * @param {Object} ad reklama z ssp
 * @param {Object} data popis pozice
 */
const urlDataToCallback = function (ad, data) {
  /* nejprve vyrobit instanci XHR */
  if (window.XMLHttpRequest) {
    var _xhr = new XMLHttpRequest();
  } else {
    return;
  }

  /* kvuli vice volani getAds si musim pamatovat aktualni data o pozicich */
  (function (ad, data) {
    var url = ad.data; // url na SKLIK API

    var errHandler = function (e, ad, data) {
      var d = e.target.responseText;

      if (d === '') {
        ad.type = 'error';
        if (data.callback) {
          data.callback(ad, data);
          return true;
        } else {
          writeAd(ad, data);
          return true;
        }
      } else {
        return false;
      }
    };

    _xhr.onload = function (e) {
      // pokud uzivatel zadal id, znamena to ze budeme stazeny kod vypisovat do stranky primo my
      // plati pro vast_url
      // pokud je typ json_url - nesmi byt zadan soucasne callback a id
      // v ostatnich pripadech ano, a vyuzijeme to k mereni visibility

      var fail = errHandler(e, ad, data); // zkontrolujeme odpoved

      if (fail) {
        return; //nechceme pokracovat
      }

      ad.data = e.target.responseText; // ziskana data z URL pridame do ad objektu

      if (ad.type === 'json_url') {
        ad.type = 'json';
        logBackgroundAdReload(ad);
        safelyExecuteCallback(() => data.callback(ad, data));
      } else {
        ad.type = 'code';
        if (data.callback) {
          logBackgroundAdReload(ad);
          safelyExecuteCallback(() => data.callback(ad, data));
        } else {
          writeAd(ad, data);
        }
      }
    };

    _xhr.onerror = function (e) {
      errHandler(e, ad, data); // zkontrolujeme odpoved
    };

    _xhr.open('GET', url);
    _xhr.withCredentials = true;
    _xhr.send();
    dbg('sending requset to SKLIK JSON API', url);
  })(ad, data);
};

export default urlDataToCallback;
