import createRequestPayload from '#Modules/ads/createRequestPayload';
import { dbg } from '#Modules/utils/dbg';
import { getKeywords } from '#Modules/utils/keywords';
import { isTopWindow } from '#Modules/utils/topWindow';
import { USER_TIMEOUT } from '#Modules/utils/user';
import { requestVastAds } from '../utils/request';
const getDataEvent = 'SSSP_GET_VAST_DATA';
const sendDataEvent = 'SSSP_SEND_VAST_DATA';
const allowedFrames = ['localhost', 'www.stream.cz', 'stream.cz', 'podcasty.seznam.cz'];
const validateZones = (zones) => {
    if (!Array.isArray(zones)) {
        return null;
    }
    const validatedZones = zones.filter(({ zoneId }) => typeof zoneId === 'number');
    return validatedZones.length ? validatedZones : null;
};
export const getVastAds = async (zones) => {
    const validatedZones = validateZones(zones);
    if (!validatedZones) {
        dbg('warn', 'VAST: Invalid zones passed into getVastAds: ', validatedZones);
        return null;
    }
    dbg('info', 'VAST: Getting vast data for valid zones: ', validatedZones);
    const vastPayload = await getVastPayload(validatedZones);
    dbg('info', 'VAST: Requesting vast data with payload: ', vastPayload);
    const vastAds = await requestVastAds(vastPayload);
    return vastAds;
};
const getVastPayload = async (zones) => {
    try {
        if (isTopWindow()) {
            dbg('info', 'VAST: (in TOP window) Getting vast data from TOP window (itself)');
            // we are in TOP frame -> just read it from "itself"
            const payload = await createRequestPayload({ zones, isVastRequest: true });
            const keywords = getKeywords().join(' ');
            const payloadWithCollocation = {
                ...payload,
                zones: payload.zones.map((zone) => {
                    const collocation = `${zone?.options?.collocation || ''} ${keywords}`.trim();
                    return { ...zone, options: { ...zone.options, collocation } };
                }),
            };
            return payloadWithCollocation;
        }
        // we are in CHILD frame -> request it from "top" window
        dbg('info', 'VAST: (in IFRAME) Getting vast data request from TOP window');
        const payload = await vastPayloadFromTop(zones);
        return payload;
    }
    catch (e) {
        dbg('error', `VAST: Error getting vast data from ${isTopWindow() ? 'TOP WINDOW' : 'IFRAME'}. Error: ${e}`);
        return null;
    }
};
const vastPayloadFromTop = (zones) => {
    return new Promise((resolve) => {
        let timeout = null;
        let interval = null;
        const fromTopHandler = (event) => {
            const { name, payload } = event.data;
            if (name === sendDataEvent && payload) {
                window.clearTimeout(timeout);
                window.clearInterval(interval);
                window.removeEventListener('message', fromTopHandler);
                resolve(payload);
            }
        };
        window.addEventListener('message', fromTopHandler);
        dbg('info', 'VAST: Sending vast data request to TOP window');
        const pingTop = () => window.parent.postMessage({ name: getDataEvent, zones }, '*');
        pingTop();
        interval = window.setInterval(pingTop, 250);
        timeout = window.setTimeout(() => {
            window.clearInterval(interval);
            dbg('warn', 'Timeout waiting for vast data');
        }, USER_TIMEOUT);
    });
};
/**
 * Listen to data requests from child frames and send them back
 */
export const registerVastDataPayloadProvider = () => {
    if (isTopWindow()) {
        window.addEventListener('message', async (event) => {
            try {
                const originHostname = new URL(event.origin).hostname;
                if (event.data.name === getDataEvent && event.data.zones && allowedFrames.includes(originHostname)) {
                    const payload = await getVastPayload(event.data.zones);
                    event.source.postMessage({ name: sendDataEvent, payload }, event.origin);
                }
            }
            catch {
                dbg('error', 'VAST: Failed to process message from child frame');
            }
        });
    }
};
