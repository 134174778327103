import { dbg } from '../utils/dbg';
import { CONSENT } from '@sklik/cmp2-common';
import { CONSENT_CHECK_TIMEOUT } from '../../constants';

/**
 * Factory for creating scoped "resolver"
 */
const resolverFactory = (resolve, timingObject) => {
  return (consent, byCookie) => {
    if (consent) {
      if (byCookie) {
        dbg('info', 'Got v2 consent from cookie', consent);
      } else {
        dbg('info', 'Got v2 consent from tcf api', consent);
      }
      timingObject.stopAll();
      resolve(consent);
    } else {
      dbg('info', 'Resolved without consent');
      timingObject.stopAll();
      resolve(null);
    }
  };
};

/**
 * Get consent + cb flow
 */
const getConsentAndAttachCallback = (resolver) => {
  return CONSENT.getConsentFromCookieList((result) => {
    if (result) {
      resolver(result, false);
    }
  });
};

/**
 * Consent checking timeout. Terminated by existing consent data (cookie / TCF event)
 */
const consentCheckingTimeout = (resolver, timingObject) => {
  const consentChecked = getConsentAndAttachCallback(resolver);
  if (consentChecked) {
    resolver(consentChecked, true);
  }

  timingObject.timeout.start(() => {
    dbg('info', 'Consent checking timeout reached');
    resolver(null, false);
  }, CONSENT_CHECK_TIMEOUT);
};

/**
 * Factory for creating "scoped time object" for easy propagation
 */
const timingFactory = function () {
  return {
    start: Date.now(),
    interval: {
      _interval: null,
      start(fn, ms) {
        this._interval = window.setInterval(fn, ms);
      },
      stop() {
        window.clearInterval(this._interval);
      },
    },
    timeout: {
      _timeout: null,
      start(fn, ms) {
        this._timeout = window.setTimeout(fn, ms);
      },
      stop() {
        window.clearTimeout(this._timeout);
      },
    },
    stopAll() {
      this.interval.stop();
      this.timeout.stop();
    },
  };
};

/**
 * Get consent in async way (optionally block flow until any consent given)
 */
export const getLatestConsent = () => {
  return new Promise((resolve) => {
    // create "scoped" timing object
    const timing = timingFactory();

    // create "scoped" resolverr -> resolve accorind to consent value
    const resolver = resolverFactory(resolve, timing);

    // SHOULD NOT WAIT FOR CONSENT ("soft" waiting -> timeout)
    consentCheckingTimeout(resolver, timing);
    dbg('info', 'Consent checking timeout started -> "timeout wait"');
  });
};

export const getLatestConsentString = async () => {
  try {
    const consent = await getLatestConsent();

    if (consent) {
      return consent.consentString;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
